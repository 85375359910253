import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Modal } from "@mui/material";
import { UiButton } from "./Ui/UiButton";
import { useAuth } from "../context/Context";
import translations from "../assets/languages.json";

export const CookiePopup = () => {
  const [isCookieAccepted, setIsCookieAccepted] = useState(true);
  const { language } = useAuth();
  const translationsData = translations[language];

  const acceptCookies = () => {
    Cookies.set("cookie_consent", "accepted", { expires: 365 }); // Set cookie for 1 year
    setIsCookieAccepted(true);
  };

  useEffect(() => {
    if (Cookies.get("cookie_consent")) {
      setIsCookieAccepted(true);
    } else {
      setIsCookieAccepted(false);
    }
  }, []);

  return (
    <Modal open={!isCookieAccepted} sx={{ display: "flex" }}>
      <div className="product Box-root p-10 m-auto bg-white flex flex-col gap-4 w-[80%] lg:w-[60%]  text-left rounded-lg text-black font-sans">
        <p>{translationsData?.cookieStatement}</p>
        <p> {translationsData?.acceptCookie}</p>

        <div>
          <UiButton id="accept-cookies" onClick={() => acceptCookies()}>
            {translationsData?.acceptCookieBtn}
          </UiButton>
        </div>
      </div>
    </Modal>
  );
};
