import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  addString,
  saveWord,
  checkAndUpdateCount,
  updateCredits,
  updateSubscription,
} from "../firebase/Firestore";
import subscriptions from "../assets/subscriptions.json";
import translations from "../assets/languages.json";
import { deleteExcessWords, getExcessWords, fetchSubscriptionStatus, getTargetDay } from "../utils";

import { useAuth } from "./Context";

const SubscriptionsContext = createContext();

export const useSubscription = () => useContext(SubscriptionsContext);

export const SubscriptionsProvider = ({ children }) => {
  const { words, currentUser, language, languageToLearn, setUser } = useAuth();
  const [remainingWords, setRemainingWords] = useState(0);
  const hasRun = useRef(false); // Use a ref to track if the effect has run

  const sub = subscriptions?.[currentUser?.subscription];
  const maxWords = sub?.maxWords;

  const addWord = async (word, invert) => {
    const maxWords = subscriptions?.[currentUser?.subscription].maxWords;
    if (words?.length < maxWords) {
      return await addString(word, language, languageToLearn, invert);
    }
  };

  const createWord = async (wordData, word) => {
    const maxWords = subscriptions?.[currentUser?.subscription].maxWords;
    if (words?.length < maxWords) {
      const newWord = await saveWord(wordData, word, translations.EN?.[languageToLearn]);
      return newWord;
    }
  };

  const canUseFeature = useCallback(async () => {
    return await checkAndUpdateCount(currentUser?.userId, sub.credits);
  }, [currentUser?.userId]);

  const updateCreditsCount = async (docRef, cost) => {
    if (docRef) {
      const updatedUser = await updateCredits(docRef, cost);
      setUser(updatedUser);
      return updatedUser?.credits ?? 0;
    } else {
      return null;
    }
  };

  const renewCreditsToUser = async () => {
    const data = await fetchSubscriptionStatus(currentUser?.dashboardId);
    const credits = subscriptions?.[data.product_name].credits;
    const user = await updateSubscription(currentUser.userId, {
      credits: credits,
      renewDate: getTargetDay(new Date(data?.current_period_end * 1000)),
    });
    if (user) setUser(user);
  };

  useEffect(() => {
    setRemainingWords(maxWords - words?.length);
  }, [words, maxWords]);

  useEffect(() => {
    if (remainingWords < 0) {
      if (currentUser?.deleteExcessWords) {
        const now = new Date();
        if (now > currentUser?.deleteExcessWords) {
          const docsToDelete = getExcessWords(words, currentUser);
          deleteExcessWords(docsToDelete);
        }
        return;
      } else {
        console.log("Setting new");
        const now = new Date();
        const newDate24hFromNow = new Date(now.getTime() + 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];

        setUser({
          ...currentUser,
          deleteExcessWords: newDate24hFromNow,
        });
      }
    }
  }, [remainingWords, setUser, currentUser?.deleteExcessWords, currentUser, words]);

  useEffect(() => {
    const newDate = new Date();
    const renewDate = new Date(currentUser?.renewDate?.seconds * 1000 ?? 0);

    if (!hasRun.current && renewDate - newDate <= 0) {
      renewCreditsToUser();
      hasRun.current = true;
    }
  }, [currentUser?.renewDate]); // Depend
  return (
    <SubscriptionsContext.Provider
      value={{
        addWord,
        createWord,
        canUseFeature,
        updateCreditsCount,
        remainingWords,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};
