import "./App.css";
import "./styles.css";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useAuth } from "./context/Context";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import TopBar from "./components/TopBar";
import { HelmetProvider } from "react-helmet-async";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { logVisitHomepage, logUserActivity } from "./ga/gaEvents.js";
import { CookiePopup } from "./components/CookiePopup.jsx";

const theme = createTheme({
  typography: {
    fontFamily: '"Lilita One", sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024, // Set lg to 1024px
      xl: 1536,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent", // Background color for the Accordion
          border: 0,
          boxShadow: "none",
          position: "initial",
          margin: "0px 0", // Margin for Accordion when not expanded
          "&:before": {
            display: "none", // Hide the default border
          },
          "&.Mui-expanded": {
            margin: "0px 0",
          },
          ".MuiAccordionSummary-root": {
            minHeight: "unset !important",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: "0px", // Default margin when not expanded
          justifyContent: "center", // Center content
          "&.Mui-expanded": {
            margin: "0 0 !important", // Margin when expanded
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "transparent", // Remove default border color
        },
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent", // Remove border on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent", // Remove border when focused
          },
        },
      },
    },
  },
});
// Lazy load components
const WordInput = lazy(() => import("./components/pages/WordInput"));
const WordList = lazy(() => import("./components/pages/WordList.jsx"));
const SignIn = lazy(() => import("./components/pages/SignIn"));
const RepeatMode = lazy(() => import("./components/pages/RepeatMode"));
const ExerciseMode = lazy(() => import("./components/ExerciseMode"));
const TextBuilder = lazy(() => import("./components/pages/TextBuilder"));
const FirstSteps = lazy(() => import("./components/FirstSteps"));
const LandingPage = lazy(() => import("./components/pages/LandingPage"));
const Contact = lazy(() => import("./components/pages/Contact"));
const Subscriptions = lazy(() => import("./components/pages/Subscriptions"));
const Account = lazy(() => import("./components/pages/Account"));
const InputBuilder = lazy(() => import("./components/pages/InputBuilder"));
const Games = lazy(() => import("./components/pages/Games"));
const CrossWords = lazy(() => import("./components/Games/Crossword.jsx"));
const WordHunter = lazy(() => import("./components/Games/WordHunter.jsx"));
const GuessTheWord = lazy(() => import("./components/Games/GuessTheWord.jsx"));
const Verbs = lazy(() => import("./components/pages/Verbs"));
const ImportantWords = lazy(() => import("./components/pages/ImportantWords.jsx"));
const GDPR = lazy(() => import("./components/pages/GDPR.jsx"));
const WaitForActivation = lazy(() => import("./components/pages/WaitForActivation.jsx"));
const Chat = lazy(() => import("./components/pages/Chat.jsx"));
const WordInfo = lazy(() => import("./components/pages/WordInfo.jsx"));
const Learn = lazy(() => import("./components/pages/Learn.jsx"));

function App() {
  const { currentUser, navSection, setNavSection, isLoading } = useAuth();
  const [isAnimating, setIsAnimating] = useState(false);
  const initil = useRef(true);

  const components = {
    "New Word": currentUser ? WordInput : LandingPage,
    List: currentUser ? WordList : LandingPage,
    "Audio Trainer": currentUser ? RepeatMode : LandingPage,
    Exercises: currentUser ? ExerciseMode : LandingPage,
    "Text Builder": currentUser ? TextBuilder : LandingPage,
    "": currentUser ? WordInput : LandingPage,
    Landing: currentUser ? WordInput : LandingPage,
    "Log In": currentUser ? WordInput : SignIn,
    Contact: Contact,
    landing: LandingPage,
    Subscriptions: currentUser ? Subscriptions : SignIn,
    Account: currentUser ? Account : SignIn,
    "Word Info": currentUser ? WordInfo : LandingPage,
    InputBuilder: currentUser ? InputBuilder : LandingPage,
    Games: currentUser ? Games : LandingPage,
    CrossWords: currentUser ? CrossWords : LandingPage,
    WordHunter: currentUser ? WordHunter : LandingPage,
    GuessTheWord: currentUser ? GuessTheWord : LandingPage,
    Verbs: currentUser ? Verbs : LandingPage,
    ImportantWords: currentUser ? ImportantWords : LandingPage,
    gdpr: GDPR,
    Chat: currentUser ? Chat : LandingPage,
    Learn: currentUser ? Learn : LandingPage,
  };
  const [ComponentToRender, setComponentToRender] = useState(components[navSection ?? ""]);

  useEffect(() => {
    if (navSection === "landing") {
      return setComponentToRender(components[navSection]);
    }
    if (!initil.current) {
      setIsAnimating(true);
    }
    setTimeout(() => {
      initil.current = false;
      setComponentToRender(
        currentUser && !currentUser.emailVerified && !currentUser.languageToLearn
          ? WaitForActivation
          : components[navSection ?? ""]
      );
      setIsAnimating(false); // Reset animation state after new component loads
    }, 200);

    // Log events
    if (currentUser) {
      logUserActivity(currentUser.id);
    } else {
      logVisitHomepage();
    }
  }, [currentUser, navSection]);

  return (
    <div className="App">
      <HelmetProvider>
        <title>Lingo Practice</title>
        <meta name="description" content="https://learn-dutch-54602.web.app/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Lingo Practice",
              "description": "https://learn-dutch-54602.web.app/",
              "url": "https://learn-dutch-54602.web.app/"
            }
          `}
        </script>
        <link rel="canonical" href="https://learn-dutch-54602.web.app/" />
      </HelmetProvider>
      <ThemeProvider theme={theme}>
        <CookiePopup />
        <Navbar setSection={setNavSection} />
        {currentUser && <TopBar />}
        {!isLoading ? (
          <Suspense fallback={<div>Loading...</div>}>
            {currentUser?.languageToLearn ? (
              <div
                className={`transition-container ${
                  isAnimating ? "slide-out-left" : "slide-in-right"
                }`}
              >
                <ComponentToRender />
              </div>
            ) : currentUser ? (
              !currentUser.emailVerified ? (
                <WaitForActivation />
              ) : (
                <FirstSteps />
              )
            ) : (
              <div
                className={`transition-container ${
                  isAnimating ? "slide-out-left" : "slide-in-right"
                }`}
              >
                <ComponentToRender />
              </div>
            )}
          </Suspense>
        ) : null}
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
