import { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const ScoreContext = createContext();

export const useScore = () => useContext(ScoreContext);

export const ScoreProvider = ({ children }) => {
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [progress, setProgress] = useState(0);
  const [gotNewLevel, setGotNewLevel] = useState(false);
  const [highScores, setHighScore] = useState({
    wordHunter: 0,
    crosswords: {
      easy: {
        timerScore: 0,
        time: 0,
      },
      medium: {
        timerScore: 0,
        time: 0,
      },
      hard: {
        timerScore: 0,
        time: 0,
      },
    },
    guessWord: 0,
  });

  const baseXP = 200;
  const increaseFactor = 1.5;

  const increaseValue = (targetValue) => {
    let startValue = 0;
    let startTime = null;
    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progressTime = timestamp - startTime;

      // Calculate the new value
      const newValue = Math.min(
        targetValue,
        startValue + (targetValue * progressTime) / 1000
      );

      // Update progress bar
      const currentXP = score + newValue;
      const xpForLevel = getXPForLevel(level, baseXP, increaseFactor);
      const newProgress = Math.floor((currentXP / xpForLevel) * 100); // Calculate percentage

      // Continue the animation until the target value is reached
      if (newValue < targetValue) {
        requestAnimationFrame(animate);
      }
      if (newProgress >= 100) {
        const newxpForLevel = getXPForLevel(level + 1, baseXP, increaseFactor);
        const newProgressLevel = Math.floor(
          ((currentXP - xpForLevel) / newxpForLevel) * 100
        ); // Calculate percentage
        setProgress(newProgressLevel);
      } else {
        setProgress(newProgress);
      }
      if (newValue + score >= xpForLevel) {
        setScore(Math.floor(newValue - xpForLevel + score));
      } else {
        setScore(Math.floor(newValue + score));
      }
    };

    requestAnimationFrame(animate);
  };

  const getXPForLevel = (level, baseXP, increaseFactor) => {
    return baseXP * Math.pow(increaseFactor, level - 1);
  };

  const addXP = (currentXP) => {
    let xpForLevel = getXPForLevel(level, baseXP, increaseFactor);
    let newXP = currentXP + score;
    let newLevel = level;

    increaseValue(currentXP);
    while (newXP >= xpForLevel) {
      console.log(newXP, newLevel, xpForLevel, "newlevel");

      setProgress(0);
      newXP -= xpForLevel; // Subtract the XP required for the current level
      newLevel++; // Increase the level
      xpForLevel = getXPForLevel(newLevel, baseXP, increaseFactor); // Update XP needed for new level
    }

    setScore(newXP);
    if (newLevel !== level) {
      setLevel(newLevel);
      setGotNewLevel(true);
    }
    Cookies.set(
      "userScore",
      JSON.stringify({
        score: newXP,
        level: newLevel,
      })
    );
    console.log(newXP);
  };

  useEffect(() => {
    const score = Cookies.get("games");
    if (score) {
      const parsedScore = JSON.parse(score);
      setHighScore((prevState) => ({ ...prevState, ...parsedScore }));
    } else {
      Cookies.set("games", JSON.stringify({ ...highScores }));
    }

    const userScore = Cookies.get("userScore");
    if (userScore) {
      const parsedScore = JSON.parse(userScore);
      setScore(parsedScore.score);
      setLevel(parsedScore.level);
      const xpNeeded = getXPForLevel(parsedScore.level, baseXP, increaseFactor);
      const newProgress = Math.floor((parsedScore.score / xpNeeded) * 100);
      setProgress(newProgress);
    }
  }, []);
  // todo save score on user firbase
  return (
    <ScoreContext.Provider
      value={{
        score,
        setScore,
        level,
        setLevel,
        progress,
        setProgress,
        increaseValue,
        addXP,
        gotNewLevel,
        setGotNewLevel,
        highScores,
        setHighScore,
      }}
    >
      {children}
    </ScoreContext.Provider>
  );
};
