import React, { useState } from "react";
import { useAuth } from "../context/Context";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Select } from "@mui/material";
import translations from "../assets/languages.json";
import Cookies from "js-cookie";

export const Navbar = ({ setSection }) => {
  const {
    logOut,
    setSelectedWord,
    currentUser,
    language,
    setLanguage,
    languageToLearn,
    navSection,
  } = useAuth();

  const pages = translations[language].pages;
  const settings = translations[language].settings;
  const languages = ["EN", "PT", "NL", "DE", "FR", "ES"].filter((lang) => lang !== languageToLearn);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = (clickedSection) => {
    if (clickedSection === "Logout") {
      setAnchorElUser(null);
      return logOut();
    }
    handleCloseNavMenu();
    if (
      clickedSection !== navSection ||
      clickedSection === "Games" ||
      (clickedSection === "New Word" && navSection === "")
    ) {
      setSection(clickedSection);
      setSelectedWord("");
    }
  };

  const handleLangChange = (newLang) => {
    setLanguage(newLang);
    Cookies.set("lang", newLang, { secure: true, sameSite: "Strict" });
  };

  return (
    <div className="z-50 top-0 left-0 p-2 pb-0 w-full">
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#118AB2",
          border: "black solid 2px",
          borderRadius: "10px",
        }}
      >
        <div className="container">
          <Toolbar disableGutters sx={{ minHeight: "0 !important" }}>
            {/* Desktop */}
            <img
              src={`${
                process.env.NODE_ENV === "development"
                  ? "http://localhost:3001"
                  : process.env.REACT_APP_BASE_URL
              }/logo512.png`}
              alt="Logo Lingo Practice"
              onClick={() => handleClick(currentUser ? "New Word" : "Landing")}
              className="h-[50px] cursor-pointer hidden lg:block"
            />
            {/* mobile menu */}
            {currentUser && (
              <Box sx={{ display: { xs: "flex", lg: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleOpenNavMenu}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav || null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  {pages.map((page, index) => (
                    <MenuItem key={page + index + "-menu"} onClick={() => handleClick(page.page)}>
                      <p>{page.translation}</p>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
            {/* mobile */}
            <img
              src={`${
                process.env.NODE_ENV === "development"
                  ? "http://localhost:3001"
                  : process.env.REACT_APP_BASE_URL
              }/logo512.png`}
              alt="Logo Lingo Practice"
              onClick={() => handleClick(currentUser ? "New Word" : "Landing")}
              className={`h-[50px] cursor-pointer lg:hidden flex ${currentUser && "mx-auto"}`}
            />

            {/* desktop items on nav */}
            {currentUser && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", lg: "flex" },
                  justifyContent: "end",
                  paddingRight: "20px",
                  gap: "5px",
                }}
              >
                {pages.map((page, index) => (
                  <div
                    key={page + index + "bar"}
                    className="hidden lg:block py-1 px-3 hover:scale-105 cursor-pointer rounded-lg hover:bg-[#119ab2e1]  transition-all duration-500"
                    onClick={() => handleClick(page.page)}
                  >
                    <p
                      className={`${
                        navSection === page.page ? "text-orange-300 font-black" : "text-white"
                      } text-base `}
                    >
                      {page.translation}
                    </p>
                  </div>
                ))}
              </Box>
            )}
            {/* TODO menu nao fecha ao clicar  */}
            {currentUser && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={currentUser.displayName} src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px", textAlign: "center" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser || null}
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <FormControl sx={{ width: "150px", marginX: "10px" }}>
                    <Select
                      value={language}
                      labelId="demo-simple-select-helper-label"
                      onChange={(e) => handleLangChange(e.target.value)}
                      sx={{
                        height: "50px",
                        fontSize: "16px",
                        padding: "10px",
                      }}
                    >
                      {languages.map((lang) => (
                        <MenuItem value={lang} key={lang}>
                          <p className="text-sm">{translations[language][lang]}</p>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {settings.map((setting, index) => (
                    <MenuItem
                      key={setting + index}
                      sx={{ justifyContent: "center" }}
                      onClick={() => handleClick(setting.page)}
                    >
                      <Typography>{setting.translation}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
};
