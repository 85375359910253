// src/firestore.js
import { db, model, auth } from "./Firebase";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  getDoc,
  orderBy,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { geminiQuery, geminiQueryNewLang, getConversationPrompt, getAiContent } from "../utils";
import { sendPasswordResetEmail } from "firebase/auth";

// TODO refactor all this , a lot of respeative code
const usersCollection = collection(db, "Users");

export const addString = async (string, language, languageToLearn, invert) => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    return false;
  }

  try {
    let stringToCreate;
    if (invert) {
      stringToCreate = await askStringTranslation(string, language, languageToLearn);
    } else {
      stringToCreate = await askStringTranslationInverted(string, language, languageToLearn);
    }
    return stringToCreate;
  } catch (error) {
    return false;
  }
};

export const createUser = async (data) => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    return false;
  }

  const userData = {
    email: currentUser.email,
    userId: currentUser.uid,
    ...data,
  };

  try {
    const docRef = await addDoc(usersCollection, userData);

    return {
      docRef: docRef,
      userData: userData,
    };
  } catch (error) {
    console.error("Error adding user: ");
    return false;
  }
};

export const saveWord = async (wordData, word, languageToLearn) => {
  const currentUser = auth.currentUser;
  const today = new Date().toISOString().split("T")[0];
  if (!currentUser) {
    return true;
  }

  try {
    const stringsCollection = collection(db, `${languageToLearn}Words`);

    const docRef = await addDoc(stringsCollection, {
      value: word,
      translations: wordData.translation,
      examples: wordData.examples,
      userId: currentUser.uid, // Associate the string with the current user
      createdAt: today,
    });

    return docRef;
  } catch (error) {
    return false;
  }
};

export const getUser = async (uid) => {
  if (!uid) return;

  try {
    // Query Firestore for documents where userId matches the given UID
    const q = query(usersCollection, where("userId", "==", uid));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      // Assuming there's only one user per UID, you can return the first match
      const userDoc = snapshot.docs[0];
      return { id: userDoc.id, ...userDoc.data() }; // Return user data with document ID
    } else {
      console.log("No such user with userId:", uid);
      return null;
    }
  } catch (error) {
    console.log("No such user with userId:", error);

    return false;
  }
};

export const checkAndUpdateCount = async (userId, max) => {
  const userQuery = query(usersCollection, where("userId", "==", userId)); // Correctly set the query for the user

  const today = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

  try {
    const snapshot = await getDocs(userQuery);

    // If the document exists, check the property and update accordingly
    if (!snapshot.empty) {
      const userDoc = snapshot.docs[0];
      const userData = userDoc.data(); // Get the document data

      // Check if the user has remaining counts for the day
      if (userData?.credits > 0) {
        return userDoc;
      } else {
        return false; // Block action if the limit is reached
      }
    } else {
      // If no document exists, create a new one with the count set to max-1
      const newUserDocRef = doc(usersCollection); // Create a new document reference
      await setDoc(newUserDocRef, {
        userId,
        credits: max - 1,
        lastUpdated: today,
      });
      return { userId, credits: max - 1, lastUpdated: today }; // Return new user data
    }
  } catch (error) {
    return false; // Handle errors gracefully
  }
};

export const updateCredits = async (userDoc, cost) => {
  const userDocRef = userDoc.ref; // Get the document reference
  const userData = userDoc.data(); // Get the document data

  await updateDoc(userDocRef, {
    credits: userData?.credits - cost, // Decrease the property count
  });
  return { ...userData, credits: userData?.credits - cost }; // Return updated data
};

// TODO Add to functions
export const updateSubscription = async (userId, data) => {
  // Create a query to find the document based on the userId
  console.log(data);
  const q = query(usersCollection, where("userId", "==", userId));

  try {
    // Execute the query to get the documents matching the userId
    const querySnapshot = await getDocs(q);

    // Check if any documents were found
    if (!querySnapshot.empty) {
      // Assuming there's only one document with the userId
      for (const doc of querySnapshot.docs) {
        const docRef = doc.ref; // Get the document reference

        // Update the document with the new data
        await updateDoc(docRef, { ...data });

        // Fetch the updated document
        const updatedDoc = await getDoc(docRef);

        // Log the updated document data
        return updatedDoc.data(); // Return the updated document data
      }
    } else {
      console.error("No document found with the given userId.");
      return null; // Return null or handle as needed
    }
  } catch (error) {
    console.error("Error updating document: ", error);
    return null; // Return null or handle as needed
  }
};
// TODO not used
export const getWordsByValue = async (value, languageToLearn) => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    return null;
  }

  try {
    const stringsCollection = collection(db, `${languageToLearn}Words`);

    const q = query(
      stringsCollection,
      where("value", "==", value),
      where("userId", "==", currentUser.uid)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0]; // Assuming you only need the first match
      return doc.data();
    } else {
      console.log("No matching documents.");
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const updateDocumentWithExamples = async (docId, newExamples, languageToLearn) => {
  const stringsCollection = collection(db, `${languageToLearn}Words`);

  const docRef = doc(stringsCollection, docId);

  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const currentData = docSnap.data();
      const updatedExamples = currentData.examples
        ? [...currentData.examples, newExamples]
        : [newExamples];
      await updateDoc(docRef, { examples: updatedExamples });

      // Fetch updated words from Firestore and update local storage
      await getWordsRealTime();
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateWord = async (docId, examples, languageToLearn) => {
  const stringsCollection = collection(db, `${languageToLearn}Words`);

  const docRef = doc(stringsCollection, docId);

  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await updateDoc(docRef, { examples: examples });

      // Fetch updated words from Firestore and update local storage
      await getWordsRealTime();
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const updateDocForNewLang = async (docId, data, languageToLearn) => {
  const stringsCollection = collection(db, `${languageToLearn}Words`);

  const docRef = doc(stringsCollection, docId);

  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const currentData = docSnap.data();
      const updatedData = { ...currentData, ...data };

      await updateDoc(docRef, { ...updatedData });

      // // Fetch updated words from Firestore and update local storage
      const updatedWords = await getWordsRealTime(languageToLearn);
      return updatedWords;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};

// TODO change to functions
export const askStringTranslation = async (string, language, languageToLearn) => {
  // To generate text output, call generateContent with the text input
  const prompt = `
  can you give me a json format answer with the ${language} translation of the  ${languageToLearn} word "${string}"please give me your answer
   in json format with at most 5 contexts. 
  example :
  ${geminiQuery(language, languageToLearn, string)}`;
  try {
    const result = await model.generateContent(prompt);

    const response = result.response.text();
    const jsonMatch = response.match(/```json([\s\S]*?)```/);

    if (jsonMatch) {
      const jsonContent = jsonMatch[1].replace(/\/\/.*$/gm, ""); // Removes comments
      const parsedObject = JSON.parse(jsonContent); // Parses the JSON object
      return parsedObject;
    } else {
      return JSON.parse(response);
    }
  } catch (e) {}
};

// TODO change to functions
export const askStringTranslationInverted = async (string, language, languageToLearn) => {
  // To generate text output, call generateContent with the text input
  const prompt = `
  can you give me a json format answer for the ${languageToLearn} translation for the  ${language} word "${string}"please give me your answer in json format with at most 3 contexts. 
  example :
  ${geminiQuery(language, languageToLearn, string)}`;
  try {
    const result = await model.generateContent(prompt);

    const response = result.response.text();
    const jsonMatch = response.match(/```json([\s\S]*?)```/);

    if (jsonMatch) {
      const jsonContent = jsonMatch[1].replace(/\/\/.*$/gm, ""); // Removes comments
      const parsedObject = JSON.parse(jsonContent); // Parses the JSON object
      return parsedObject;
    } else {
      return JSON.parse(response);
    }
  } catch (e) {
    console.error("Invalid JSON:", e);
  }
};

// TODO change to functions
export const askStringTranslationNewLang = async (language, data) => {
  // To generate text output, call generateContent with the text input
  const prompt = geminiQueryNewLang(language, data);
  try {
    const result = await model.generateContent(prompt);

    const response = result.response.text();
    const jsonMatch = response.match(/```json([\s\S]*?)```/);

    if (jsonMatch) {
      const jsonContent = jsonMatch[1].replace(/\/\/.*$/gm, ""); // Removes comments
      const parsedObject = JSON.parse(jsonContent); // Parses the JSON object
      return parsedObject;
    } else {
      return JSON.parse(response);
    }
  } catch (e) {
    console.error("Invalid JSON:", e);
  }
};

// TODO change to functions
export const getContextExamples = async (string, language, languageToLearn) => {
  const prompt = `
  can you give me a json format answer for the ${language} translation for the ${language} word "${string}"please give me your answer in json format with at most 3 contexts. 
  example :
  ${geminiQuery(language, languageToLearn, string, string)}`;
  try {
    const result = await model.generateContent(prompt);

    const response = result.response.text();
    const jsonMatch = response.match(/```json([\s\S]*?)```/);

    if (jsonMatch) {
      const jsonContent = jsonMatch[1].replace(/\/\/.*$/gm, ""); // Removes comments
      const parsedObject = JSON.parse(jsonContent); // Parses the JSON object
      return parsedObject;
    } else {
      return JSON.parse(response);
    }
  } catch (e) {
    console.error("Invalid JSON:", e);
  }
};

// TODO change to functions
export const getWordExamples = async (word, language, languageToLearn, currentExamples) => {
  const exampleList = currentExamples.map((example) => example?.[languageToLearn]).join(", ");
  const prompt = `
Generate three unique sentences in ${languageToLearn} using the word "${word}" in various neutral, everyday contexts. Ensure each example differs from any of the following: [${exampleList}].

Output the result as a JSON array with each example formatted as follows:

[
  {
    "${languageToLearn}": "Example sentence 1 in ${languageToLearn}",
    "${language}": "Translation of example sentence 1 in ${language}"
  },
  {
    "${languageToLearn}": "Example sentence 2 in ${languageToLearn}",
    "${language}": "Translation of example sentence 2 in ${language}"
  },
  {
    "${languageToLearn}": "Example sentence 3 in ${languageToLearn}",
    "${language}": "Translation of example sentence 3 in ${language}"
  }
]

Provide only the JSON array without additional comments or explanations.
  `;

  try {
    const result = await model.generateContent(prompt);
    const responseText = result.response.text();

    // Attempt direct JSON parsing
    try {
      return JSON.parse(responseText);
    } catch (parseError) {
      // Extract and parse JSON array if extra characters are present
      const jsonMatch = responseText.match(/\[.*?\]/s);
      if (jsonMatch) {
        return JSON.parse(jsonMatch[0]);
      }
      throw new Error("Failed to parse JSON array.");
    }
  } catch (e) {
    console.error("Error fetching or parsing JSON:", e);
    return null;
  }
};

// TODO change to functions
export const getTextExamples = async (words, language, languageToLearn) => {
  try {
    const prompt = ` Create a coherent and neutral story in ${languageToLearn} using each word from the following array: [${words}].
    - The story should be at least 10 words long per word in the array (e.g., 10 words = 100+ words in the story).
    - Structure the output as a JSON object with the story in ${languageToLearn} and its ${language} translation:
      {
        "${languageToLearn}": "Your story in ${languageToLearn}",
        "${language}": "Your story translated to ${language}"
      }
    - If only one word is provided, write a single, simple sentence.
    - **Output strictly as JSON**. Do not include any extra text, comments, or code formatting such .
  `;
    const result = await model.generateContent(prompt);

    const response = result.response.text();
    const jsonMatch = response.match(/```json([\s\S]*?)```/);

    if (jsonMatch) {
      const jsonContent = jsonMatch[1].replace(/\/\/.*$/gm, ""); // Removes comments
      try {
        const parsedObject = JSON.parse(jsonContent); // Parses the JSON object
        return parsedObject;
      } catch (e) {
        console.error("Invalid JSON:", e);
      }
    } else {
      return JSON.parse(response);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getWordsRealTime = async (languageToLearn, userId) => {
  if (!userId) return;
  const stringsCollection = collection(db, `${languageToLearn}Words`);
  try {
    // Set up the query with ordering by "value" field alphabetically
    let q = query(
      stringsCollection,
      where("userId", "==", userId),
      orderBy("value", "asc") // Order by the "value" field alphabetically
    );

    // Try fetching from the cache first
    try {
      const cachedSnapshot = await getDocs(q, { source: "cache" });
      if (!cachedSnapshot.empty) {
        const docs = cachedSnapshot.docs;

        const result = docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        return result;
      }
    } catch (error) {
      console.error("No cached data found, fetching from server...", error);
    }

    // If no cache is found or an error occurs, fetch from the server
    const snapshot = await getDocs(q, { source: "server" });
    const docs = snapshot.docs;

    const result = docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    return result;
  } catch (error) {
    return null;
  }
};

export const deleteWord = async (wordId, languageToLearn) => {
  try {
    const stringsCollection = collection(db, `${languageToLearn}Words`);

    // Reference to the document you want to delete
    const wordDocRef = doc(stringsCollection, wordId); // Adjust the collection name

    // Delete the document
    await deleteDoc(wordDocRef);

    return true;
  } catch (error) {
    return null;
  }
};

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
    // Show a success message to the user
  } catch (error) {
    console.log("Password reset email sent successfully");
    // Handle errors (e.g., invalid email)
    if (error.code === "auth/user-not-found") {
      console.log("No user found with this email.");
    } else {
      console.log("An error occurred:", error.message);
    }
  }
};

export const createCheckoutSession = async (price) => {
  try {
    const response = await fetch(
      `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createCheckoutSession`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ lookup_key: price?.id }), // The price lookup_key
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create checkout session");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
  }
};

// TODO change to functions
export const getConversation = async (text, language, languageToLearn) => {
  try {
    const prompt = getConversationPrompt(language, languageToLearn, text);
    const result = await model.generateContent(prompt);

    const response = result.response.text();
    const jsonMatch = response.match(/```json([\s\S]*?)```/);
    if (jsonMatch) {
      try {
        const jsonContent = jsonMatch[1].replace(/\/\/.*$/gm, ""); // Removes comments
        const parsedObject = JSON.parse(jsonContent); // Parses the JSON object
        return parsedObject;
      } catch (e) {
        console.error("Invalid JSON:", e);
        return null;
      }
    } else {
      return JSON.parse(response);
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

// TODO change to functions
export const getVerbsExercises = async (language, languageToLearn, verb) => {
  const prompt = `
Generate 15 language exercises for the ${languageToLearn} verb "${verb}" in various verb tenses. Each exercise should include:
- A sentence with the verb conjugation replaced by [answer]
- A complete sentence with the correct conjugation (correctAnswer)
- An English translation of the full sentence
- A tense label chosen from: "Present tense", "Present perfect tense", "Past tense", "Future tense", "Conditional mood", "Subjunctive mood", "Past perfect tense", "Future perfect tense", "Conditional perfect tense", or "Imperative mood"

Requirements:
- Use different topics for each sentence (e.g., sports, nature, daily life) to avoid repetition.
- Vary the grammatical person (e.g., first person, third person).
- Maintain unique phrasing for each exercise.
- Translation should be in ${language}.
- Format the output as a JSON object like this:

{
  exercises: [
    {
      correctAnswer: "Ik leer Nederlands.",
      exercise: "Ik [answer] Nederlands.",
      translation: "I learn Dutch.",
      tense: "Present tense"
    },
    {
      correctAnswer: "Ik heb Nederlands geleerd.",
      exercise: "Ik [answer] Nederlands [answer].",
      translation: "I have learned Dutch.",
      tense: "Present perfect tense"
    }
    // ...10 more exercises
  ]
}

Only return the JSON object, without any comments.`;

  const response = await getAiContent(prompt);
  return response;
};

export const saveVerbs = async (language, verb) => {
  try {
    const stringsCollection = collection(db, `Verbs`);

    const docRef = await addDoc(stringsCollection, {
      ...verb,
      language,
    });

    return docRef;
  } catch (error) {
    console.error("Error adding string: ", error);
  }
};

export const saveText = async (text, userId, name, collectionName) => {
  try {
    const stringsCollection = collection(db, collectionName);

    const docRef = await addDoc(stringsCollection, {
      text,
      userId,
      name,
    });
    const textNameCollection = collection(db, `SavedDocs`);
    const userQuery = query(textNameCollection, where("userId", "==", userId));
    const querySnapshot = await getDocs(userQuery);

    if (!querySnapshot.empty) {
      // If a document with the userId exists, update the field for the given collectionName
      const userDoc = querySnapshot.docs[0]; // Assuming one document per user
      const userDocRef = doc(db, "SavedDocs", userDoc.id);
      console.log(userDoc.data()); // Log the document data
      await updateDoc(userDocRef, {
        [collectionName]: [...(userDoc.data()?.[collectionName] || []), name], // Append the new name to the existing array
      });
    } else {
      // If no document exists, create a new document in `SavedDocs`
      await addDoc(textNameCollection, {
        userId,
        [collectionName]: [name],
      });
    }

    return docRef;
  } catch (error) {
    console.error("Error adding string: ", error);
  }
};

export const getSavedTexts = async (userId) => {
  try {
    const stringsCollection = collection(db, "SavedDocs");

    const q = query(stringsCollection, where("userId", "==", userId)); // Select only the "name" field

    // Try fetching from the cache first
    const cachedSnapshot = await getDocs(q, { source: "cache" });
    if (!cachedSnapshot.empty) {
      const docs = cachedSnapshot.docs;

      const result = docs.map((doc) => ({
        BuildTexts: doc.data()?.BuildTexts ?? [],
        InputTexts: doc.data()?.InputTexts ?? [],
      }));

      return result?.[0];
    }
  } catch (error) {
    console.error("No cached data found, fetching from server...", error);
  }
};

export const getSavedText = async (userId, name, collectionName) => {
  try {
    const stringsCollection = collection(db, collectionName);
    const q = query(stringsCollection, where("userId", "==", userId), where("name", "==", name)); // Select only the "name" field

    // Try fetching from the cache first
    const cachedSnapshot = await getDocs(q, { source: "cache" });
    if (!cachedSnapshot.empty) {
      const docs = cachedSnapshot.docs;

      const result = docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return result;
    }
  } catch (error) {
    console.error("No cached data found, fetching from server...", error);
  }
};

export const deleteText = async (userId, textName, collectionName) => {
  try {
    const stringsCollection = collection(db, collectionName);

    // Query for the document(s) where "name" equals the specified wordName
    const wordQuery = query(
      stringsCollection,
      where("name", "==", textName),
      where("userId", "==", userId)
    );
    const querySnapshot = await getDocs(wordQuery);

    if (!querySnapshot.empty) {
      // Iterate over each matching document and delete it
      const deletePromises = querySnapshot.docs.map((docSnapshot) =>
        deleteDoc(doc(db, collectionName, docSnapshot.id))
      );
      const textNameCollection = collection(db, `SavedDocs`);
      const userQuery = query(textNameCollection, where("userId", "==", userId));
      const newquerySnapshot = await getDocs(userQuery);

      if (!newquerySnapshot.empty) {
        // If a document with the userId exists, update the field for the given collectionName
        const userDoc = newquerySnapshot.docs[0]; // Assuming one document per user
        const userDocRef = doc(db, "SavedDocs", userDoc.id);
        const updatePromise = await updateDoc(userDocRef, {
          [collectionName]: [
            ...(userDoc.data()?.[collectionName].filter((name) => name !== textName) || []),
          ], // Append the new name to the existing array
        });
      }
      // Wait for all deletions to complete
      await Promise.all(deletePromises);

      return true; // Return true if deletion is successful
    } else {
      console.log("No documents found with the specified name");
      return false; // Return false if no documents match the query
    }
  } catch (error) {
    console.error("Error deleting document: ", error);
    return null;
  }
};

export const saveLesson = async (data, colletion) => {
  try {
    // const stringsCollection = collection(db, "EN_Lessons");
    const stringsCollection = collection(db, colletion);

    const docRef = await addDoc(stringsCollection, {
      ...data,
    });

    return docRef;
  } catch (error) {
    console.error("Error adding string: ", error);
  }
};

export const getLessons = async () => {
  try {
    // Get a reference to the collection
    const querySnapshot = await getDocs(collection(db, "EN_Lessons"));

    // Create an array to hold the documents
    const documents = [];

    // Iterate through the documents
    querySnapshot.forEach((doc) => {
      // Push each document's data and ID to the array
      documents.push({ id: doc.id, ...doc.data() });
    });

    return documents; // Return the array of documents
  } catch (error) {
    console.error("Error fetching documents: ", error);
    return null; // Handle errors as needed
  }
};

export const translateLessons = async (language, lesson) => {
  const prompt = ` translate the following json file into ${language}. 
${JSON.stringify(lesson)}

Only return the JSON object, without any comments.`;
  const response = await getAiContent(prompt);
  return response;
};
