import React from "react";
import { useAuth } from "../context/Context";
import translations from "../assets/languages.json";

export const Footer = () => {
  const { setNavSection, language } = useAuth();
  return (
    <div className="bg-[#118AB2]">
      <div className="flex gap-4 container py-10 text-white">
        <p className="cursor-pointer text-white" onClick={() => setNavSection("Contact")}>
          {translations[language].contactUs}
        </p>
        <p className="cursor-pointer text-white" onClick={() => setNavSection("gdpr")}>
          GDPR
        </p>
      </div>
    </div>
  );
};
