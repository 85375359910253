import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { deleteWord, updateSubscription, saveLesson } from ".//firebase/Firestore";
import subscriptions from "./assets/subscriptions.json";
import { model } from "./firebase/Firebase";

export const callSpeak = (text, onEndCallback, voices, rate = 0.7, language = "en") => {
  const newUtterance = new SpeechSynthesisUtterance(text);
  const filteredVoices = voices.filter((voice) => voice.lang.startsWith(language));
  const bestVoices = { nl: 2, pt: 6, en: 0, es: 18, fr: 20, de: 0 };
  const voice = filteredVoices?.[bestVoices?.[language]] ?? filteredVoices[0];

  if (voice) {
    newUtterance.voice = voice;
    newUtterance.lang = voice.lang;
  }

  newUtterance.onend = () => {
    if (onEndCallback) {
      onEndCallback();
    }
  };

  speechSynthesis.cancel();
  if (speechSynthesis.paused) {
    speechSynthesis.resume();
  }
  setTimeout(() => speechSynthesis.speak(newUtterance), 200);
};

export const geminiQuery = (language, languageToLearn, word) => `
Please generate a JSON object with the following structure for the word "${word}" in ${languageToLearn}:

{
  "translation": { 
    "${language}": ["translatedWord1", "translatedWord2"] 
  }, 
  "value": "${word}", 
  "examples": [
    { 
      "${languageToLearn}": "Example sentence in ${languageToLearn}", 
      "${language}": "Translation of the example sentence in ${language}" 
    },
    ...
  ]
}

- **translation**: If the word "${word}" is correct, populate it with a maximum of 6 relevant translations in ${language}.
- **value**: Ensure this field contains the correctly spelled form of "${word}" in ${languageToLearn}. Correct the spelling if necessary.
- **examples**: Include 5 example sentences, each with the phrase in ${languageToLearn} and its corresponding translation in ${language}. Use diverse, natural phrases that reflect real-world usage.

### Special Instructions:
1. **Language Match**: If "${word}" is not in ${languageToLearn}, translate it to ${languageToLearn} and provide the correct spelling in the **value** field.
2. **Output Format**: Provide a valid JSON object without any additional comments or explanations.

Example:
{
  "translation": { "${language}": ["correctedTranslation1", "correctedTranslation2"] },
  "value": "correctedWord",
  "examples": [
    { "${languageToLearn}": "Correct phrase in ${languageToLearn}", "${language}": "Translation in ${language}" }
  ]
}
`;

export const geminiQueryNewLang = (language, data) => `
can you give me a json format answer with the translation a a certain data.
  example :
    "translation":["crazy","mad"]
  "examples": [“That's a crazy idea!","you are mad"]
  }
  please just give the json on your answer without any other characters or comments.
  if the word can have multiple translations put them on the field "translation" to a maximun of 4 words
  translation should be an array  with the translation into ${language} of the following array [${data.translations}]
  examples should be an array with the translation into ${language}  of the following array [${data.examples}]

  it is very important that you're answer do not contain any comments`;

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
};

// Function to decrypt the data
export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Set cookie with encrypted data
export const setEncryptedCookie = (key, value, expirationDays) => {
  const encryptedValue = encryptData(value);
  Cookies.set(key, encryptedValue, { expires: expirationDays });
};

// Get cookie and decrypt the data
export const getDecryptedCookie = (key) => {
  const cookieValue = Cookies.get(key);
  if (cookieValue) {
    return JSON.parse(decryptData(cookieValue));
  }
  return null;
};

// TODO https://openart.ai/create
export const generateImagePrompt = (word) => {
  return `I want to create an automated prompt to a AI image generator. in this case i use it for the word desire, but i would like to have a similar example for the word :"${word}", you're answer should be a json format answer with a property prompt 
prompt:(desire) intimate home scene, a person sitting at a table, gazing at a fresh sunset, warm golden tones, soft light casting shadows, evoking yearning and reflection, cozy setting with books and an aromatic candle, meaningful moment, capturing the essence of longing, high detail, HD quality, serene atmosphere.`;
};

export const fetchSubscriptionStatus = async (sessionId) => {
  try {
    const response = await fetch(
      `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/getSubscriptionStatus`, // Replace with your function URL
      {
        method: "POST", // Ensure the method matches what the cloud function expects
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id: sessionId }), // Send session_id in the request body
      }
    );

    // Parse the JSON response
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching subscription status:", error);
  }
};

export const getDate = (date) => {
  const expirationTimeMillis = date?.seconds * 1000 + date?.nanoseconds / 1000000;
  return new Date(expirationTimeMillis);
};

export const getFormatedData = (expirationDate) => {
  const convertedDate = getDate(expirationDate);
  return `${convertedDate.getDate()}/${
    convertedDate.getMonth() + 1
  }/${convertedDate.getFullYear()}`;
};

export const isSubExpired = (expirationDate) => {
  const expirationDateObj = getDate(expirationDate);

  // Get the current date and time
  const currentDate = new Date();

  // Compare if expirationDate has passed
  if (expirationDateObj < currentDate) {
    return true;
  } else {
    return false;
  }
};

export const upgradeSubscription = async (subscriptionId, newPriceId) => {
  try {
    const response = await fetch(
      `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/testUpgradeSubscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId,
          newPriceId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to upgrade subscription");
    }

    const data = await response.json();
    // window.location.href = data.url; // Redirect to Stripe Checkout
    return data;
  } catch (error) {
    console.error("Error upgrading subscription:", error);
  }
};

export const cancelSubscription = async (subscriptionId, cancelAtEnd = false) => {
  try {
    const response = await fetch(
      `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/cancelSubscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriptionId,
          cancelAtEnd,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to canceled subscription");
    }

    const data = await response.json();
    // window.location.href = data.url; // Redirect to Stripe Checkout

    return data;
  } catch (error) {
    console.error("Error canceled subscription:", error);
  }
};

export const saveSubOnUser = async (sessionId, currentUser, isUpgrade) => {
  const newData = {};
  const data = await fetchSubscriptionStatus(sessionId);
  const unixTimestamp = data.current_period_end; // Example value from Stripe
  // Convert the Unix timestamp to milliseconds and create a new Date object
  const expirationDate = new Date(unixTimestamp * 1000);

  const credits = isUpgrade ? subscriptions?.[data?.product_name]?.credits : currentUser.credits;

  newData["expirationDate"] = expirationDate;
  newData["dashboardId"] = sessionId;
  newData["subscription"] = data?.product_name;
  newData["credits"] = credits;
  newData["subscriptionId"] = data?.subscription_id;
  newData["status"] = "active";
  newData["renewDate"] = getTargetDay(expirationDate);

  // if (currentUser.subscriptionId && currentUser.subscriptionId !== newData["subscriptionId"]) {
  //   currentUser.subscriptionId && (await cancelSubscription(currentUser.subscriptionId));
  return await updateSubscription(currentUser.userId, newData);

  // }
};

export const fetchTTS = async (text) => {
  const response = await fetch(
    `https://texttospeech.googleapis.com/v1/text:synthesize?key=AIzaSyDcFX2TaHD_l1AiQ6UUvMPGLmKL-U4GSig`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        input: { text: text },
        voice: { languageCode: "en-US", name: "en-US-Wavenet-D" },
        audioConfig: { audioEncoding: "MP3" },
      }),
    }
  );

  const data = await response.json();
  const audioSrc = "data:audio/mp3;base64," + data.audioContent;
  const audio = new Audio(audioSrc);
  audio.play();
};

export const getConversationPrompt = (language, languageToLearn, text) => {
  return `Create a structured conversation between two people on the topic: "${text}". The conversation should be written in ${languageToLearn}, with each person contributing at least 1 lines, resulting in a minimum of 10 lines in total. The response should only be in JSON format, without any extra characters, comments, or explanations.

Your output must include:
- this is very important, If the topic contains references to explicit content (sex, drugs, violence, hate speech, illegal activities, self-harm, insults, political or religious topics), return an empty object.
- A JSON object where the keys are the language codes "${language}" (for the translation) and "${languageToLearn}" (for the original conversation).
- Each key should contain an array with the conversation, where each person’s statements are grouped.

Example structure:

{
  "${languageToLearn}": {
    "Person1": ["First sentence of person 1", "Second sentence of person 1", ...],
    "Person2": ["First sentence of person 2", "Second sentence of person 2", ...]
  },
  "${language}": {
    "Person1": ["First translated sentence of person 1", "Second translated sentence of person 1", ...],
    "Person2": ["First translated sentence of person 2", "Second translated sentence of person 2", ...]
  }
}

Make sure the JSON response only includes the conversation without any additional explanations.`;
};

export const sortByOldest = (words, currentUser) => {
  return words?.sort(
    (a, b) =>
      new Date(a?.data?.createdAt ?? new Date()) - new Date(b?.data?.createdAt ?? new Date())
  );
};

export const sortByNewst = (words, currentUser) => {
  return words?.sort(
    (a, b) =>
      new Date(b?.data?.createdAt ?? new Date()) - new Date(a?.data?.createdAt ?? new Date())
  );
};

export const getExcessWords = (words, currentUser) => {
  return sortByOldest(words, currentUser).slice(
    0,
    words.length - subscriptions[currentUser.subscription].maxWords
  );
};

export const deleteExcessWords = async (excessWords) => {
  await Promise.all(
    excessWords.map(async (word) => {
      await deleteWord(word.id);
    })
  );
};
export const storeData = (data, storageKey) => {
  const timestamp = Date.now();
  localStorage.setItem(storageKey, JSON.stringify({ data, timestamp }));
};

export const getData = (storageKey) => {
  const expirationTime = 24 * 60 * 60 * 1000; // 1 day in milliseconds

  const storedData = localStorage.getItem(storageKey);
  if (storedData) {
    const { data, timestamp } = JSON.parse(storedData);
    const currentTime = Date.now();
    if (currentTime - timestamp > expirationTime) {
      // Data is expired, remove it
      localStorage.removeItem(storageKey);
      return null;
    }
    return data;
  }
  return null;
};

export const getAiContent = async (prompt) => {
  try {
    const result = await model.generateContent(prompt);

    const response = result.response.text();
    const jsonMatch = response.match(/```json([\s\S]*?)```/);

    if (jsonMatch) {
      const jsonContent = jsonMatch[1].replace(/\/\/.*$/gm, ""); // Removes comments
      try {
        const parsedObject = JSON.parse(jsonContent); // Parses the JSON object
        return parsedObject;
      } catch (e) {
        console.error("Invalid JSON:", e);
      }
    } else {
      return JSON.parse(response);
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const topVerbs = [
  "be",
  "have",
  "do",
  "go",
  "come",
  "see",
  "give",
  "take",
  "think",
  "find",
  "can",
  "want",
  "must",
  "stay",
  "work",
  "begin",
  "learn",
  "talk",
  "say",
  "look",
  "hold",
  "know",
  "ask",
  "answer",
  "walk",
  "put",
  "smell",
  "get",
  "lose",
  "try",
  "sit",
  "write",
  "understand",
];

export const tensesConnection = [
  {
    NL: "Present tense",
    EN: "Present Simple",
    PT: "Present tense",
    ES: "Present tense",
    FR: "Present tense",
    DE: "Present tense",
  },
  {
    NL: "Present perfect tense",
    EN: "Present Perfect",
    PT: "Present perfect tense",
    ES: "Present perfect tense",
    FR: "Present perfect tense",
    DE: "Past perfect tense",
  },
  {
    NL: "Past tense",
    EN: "Past Simple",
    PT: "Past preterite tense",
    ES: "Past preterite tense",
    FR: "Past preterite tense",
    DE: "Past preterite tense",
  },
  {
    NL: "Future tense",
    EN: "Future Simple",
    PT: "Future tense",
    ES: "Future tense",
    FR: "Future tense",
    DE: "Future tense",
  },
  {
    NL: "Conditional mood",
    EN: "Conditional Simple",
    PT: "Conditional mood",
    ES: "Conditional mood",
    FR: "Conditional mood",
  },
  {
    NL: "Subjunctive mood",
    PT: "Subjunctive mood",
    ES: "Present subjunctive tense",
    FR: "Present subjunctive tense",
  },
  {
    NL: "Past perfect tense",
    EN: "Past Perfect",
    PT: "Past perfect tense",
    ES: "Past perfect tense",
    FR: "Past perfect tense",
    DE: "Pluperfect tense",
  },
  {
    NL: "Future perf.",
    EN: "Future Perfect",
    PT: "Future perfect tense",
    ES: "Future perfect tense",
    FR: "Future perfect tense",
    DE: "Future perf.",
  },
  {
    NL: "Conditional perfect tense",
    EN: "Conditional Perfect",
    PT: "Conditional perfect tense",
    ES: "Conditional perfect tense",
    FR: "Conditional perfect tense",
  },
  {
    NL: "Imperative mood",
    PT: "Imperative mood",
    ES: "Imperative mood",
    FR: "Imperative mood",
    DE: "Imperative mood",
  },
  { ES: "Imperative negative mood", FR: "Imperative mood" },
  {
    EN: "Present Progressive",
    PT: "Present Progressive",
    ES: "Present Progressive",
    FR: "Present Progressive",
  },
  {
    EN: "Future Progressive",
    PT: "Future Progressive",
    ES: "Future Progressive",
    FR: "Future Progressive",
  },
  {
    EN: "Past Progressive",
    PT: "Past Progressive",
    ES: "Past Progressive",
    FR: "Past impf. tense",
  },
  {
    EN: "Conditional Progressive",
    PT: "Conditional Progressive",
    ES: "Conditional Progressive",
  },
  {
    EN: "Present Perfect Progressive",
    PT: "Present Perfect Progressive",
    ES: "Present Perfect Progressive",
    FR: "Present perfect tense",
  },
  {
    EN: "Future Perfect Progressive",
    PT: "Future Perfect Progressive",
    ES: "Future Perfect Progressive",
    FR: "Future perfect tense",
  },
  {
    EN: "Past Perfect Progressive",
    PT: "Past Perfect Progressive",
    ES: "Past Perfect Progressive",
    FR: "Past perfect tense",
  },
  {
    EN: "Conditional Perfect Progressive",
    PT: "Conditional Perfect Progressive",
    ES: "Conditional Perfect Progressive",
    FR: "Conditional perfect tense",
  },
  { FR: "Past anterior tense" },
  { FR: "Imperfect subjunctive tense" },
  { FR: "Present perf. subjunctive tense" },
  { FR: "Past perfect subjunctive tense" },
  { FR: "Past perfect imperative mood" },
  { DE: "Subjunctive II preterite tense" },
  { DE: "Subjunctive II future tense" },
  { DE: "Subjunctive II future perfect tense" },
  { DE: "Subjunctive I present tense" },
  { DE: "Subjunctive I present perfect tense" },
  { DE: "Subjunctive I future tense" },
  { DE: "Subjunctive I pluperfect tense" },
  { DE: "Subjunctive I future perfect tense" },
];

export function oneMonthFromNow() {
  const today = new Date();
  const day = today.getUTCDate();
  const month = today.getUTCMonth();
  const year = today.getUTCFullYear();

  // Create a new date object, set to one month ahead in UTC
  const nextMonthDate = new Date(Date.UTC(month === 11 ? year + 1 : year, (month + 1) % 12, day));

  // Check if we "overflowed" into the following month
  if (nextMonthDate.getUTCDate() !== day) {
    nextMonthDate.setUTCDate(0); // Set to the last day of the previous month in UTC
  }

  return nextMonthDate;
}

export function getTargetDay(currentPeriodEnd) {
  const periodEndDate = new Date(currentPeriodEnd); // Convert Unix timestamp to date
  const targetDay = periodEndDate.getUTCDate(); // Day from the current period end
  const today = new Date(); // Set to November 4, 2024
  const todayDate = today.getUTCDate();
  // Calculate the next month and adjust the year if needed
  let targetMonth = today.getUTCMonth(); // Next month
  let targetYear = today.getUTCFullYear();

  if (targetMonth > 11) {
    // Handle December overflow to January
    targetMonth = 0; // January
    targetYear += 1;
  }
  // console.log(targetDay,targetMonth)
  // Create a target date for the next month using the target day
  let targetDate = new Date(
    Date.UTC(targetYear, todayDate >= targetDay ? targetMonth + 1 : targetMonth, targetDay)
  );

  return targetDate;
}

export function handleOnRecord(
  isActive,
  recognitionRef,
  setIsActive,
  languageToLearn,
  getSpeechToText = () => {},
  expectedText = ""
) {
  if (isActive) {
    recognitionRef.current?.stop();
    setIsActive(false);
    return;
  }

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  recognitionRef.current = new SpeechRecognition();
  recognitionRef.current.lang = languageToLearn; // Set language for speech recognition
  recognitionRef.current.continuous = true; // Keep listening continuously

  recognitionRef.current.onstart = function () {
    setIsActive(true);
  };

  recognitionRef.current.onend = function () {
    // Restart recognition if active to keep listening
    if (isActive) {
      recognitionRef.current.start();
    } else {
      setIsActive(false);
    }
  };

  recognitionRef.current.onresult = async function (event) {
    const transcript = event.results[0][0].transcript;
    getSpeechToText(transcript);

    recognitionRef.current.start();
    if (expectedText) {
      console.log("dasdas");
      return similarityPercentage(expectedText.toLowerCase(), transcript.toLowerCase());
    }
  };
}

function levenshteinDistance(a, b) {
  const matrix = Array.from({ length: a.length + 1 }, () => Array(b.length + 1).fill(0));

  for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
  for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

  for (let i = 1; i <= a.length; i++) {
    for (let j = 1; j <= b.length; j++) {
      const cost = a[i - 1] === b[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + cost
      );
    }
  }

  return matrix[a.length][b.length];
}

function similarityPercentage(expected, spoken) {
  const distance = levenshteinDistance(expected, spoken);
  const maxLength = Math.max(expected.length, spoken.length);
  return ((maxLength - distance) / maxLength) * 100;
}

// export const createLesson = async () => {
//   const data = `{
//   "name": "Dias da Semana",
//   "vocabulary": [
//     {
//       "word": "Segunda-feira",
//       "examples": [
//         "Segunda-feira é o primeiro dia da semana.",
//         "Eu tenho uma reunião na segunda-feira."
//       ]
//     },
//     {
//       "word": "Terça-feira",
//       "examples": [
//         "Terça-feira é um bom dia para o treinamento.",
//         "Nós nos encontramos na terça-feira."
//       ]
//     },
//     {
//       "word": "Quarta-feira",
//       "examples": [
//         "Quarta-feira é o dia da semana após terça-feira.",
//         "Na quarta-feira, eu tenho muito o que fazer."
//       ]
//     },
//     {
//       "word": "Quinta-feira",
//       "examples": [
//         "Quinta-feira é meu dia favorito.",
//         "Eu vou ao cinema na quinta-feira."
//       ]
//     },
//     {
//       "word": "Sexta-feira",
//       "examples": [
//         "Sexta-feira é o último dia de trabalho da semana.",
//         "Nós vamos jantar na sexta-feira."
//       ]
//     },
//     {
//       "word": "Sábado",
//       "examples": [
//         "Sábado é um bom dia para passeios.",
//         "No sábado, eu faço as tarefas de casa."
//       ]
//     },
//     {
//       "word": "Domingo",
//       "examples": [
//         "Domingo é um dia de descanso.",
//         "Eu passo o domingo com minha família."
//       ]
//     }
//   ],
//   "grammar": [
//     {
//       "topic": "Estrutura da Frase e Ordem das Palavras",
//       "description": "Em português, a estrutura básica da frase segue a ordem Sujeito-Verbo-Objeto (SVO). Por exemplo: 'Ela (Sujeito) come (Verbo) uma maçã (Objeto).'",
//       "examples": [
//         "Eu gosto de maçãs.",
//         "Eles vão ao parque.",
//         "Ele bebe café toda manhã."
//       ]
//     },
//     {
//       "topic": "Uso Formal e Informal da Linguagem",
//       "description": "Em português, existem diferentes níveis de formalidade. A linguagem formal é usada em contextos profissionais ou educados, enquanto a linguagem informal é usada entre amigos ou familiares.",
//       "examples": [
//         "Formal: 'Bom dia, como posso ajudá-lo?'",
//         "Informal: 'Oi! O que há?'"
//       ]
//     }
//   ]
// }
// `;

//   const newData = JSON.parse(data);
//   const test = await saveLesson({ lesson: 5, ...newData }, "PT_Lessons");
//   console.log(test);
// };
