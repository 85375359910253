import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/Firebase";
import Cookies from "js-cookie";

export const logVisitHomepage = () => {
  // Exit if in development environment
  if (process.env.NODE_ENV === "development") return;

  // Get the current date
  const today = new Date();
  const todayString = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD

  // Create a date for midnight of the next day
  const nextDay = new Date();
  nextDay.setDate(today.getDate() + 1);
  nextDay.setHours(0, 0, 0, 0); // Set time to midnight

  // Check if the last_visit cookie exists
  const lastVisit = Cookies.get("last_visit");

  if (!lastVisit) {
    // If cookie does not exist, set the cookie
    Cookies.set("last_visit", todayString, { expires: nextDay });

    // Log the visit
    logEvent(analytics, "visit_home", {
      timestamp: new Date().toISOString(), // Log current timestamp
      activity_type: "page_view", // Activity type
    });
  }
};

export const logUserActivity = (userId) => {
  if (process.env.NODE_ENV === "development") return;
  const today = new Date();
  const todayString = today.toISOString().split("T")[0]; // Format: YYYY-MM-DD

  // Create a date for midnight of the next day
  const nextDay = new Date();
  nextDay.setDate(today.getDate() + 1);
  nextDay.setHours(0, 0, 0, 0); // Set time to midnight

  // Check if the last_visit cookie exists
  const lastVisit = Cookies.get("last_visit");

  if (!lastVisit) {
    // If cookie does not exist, set the cookie
    Cookies.set("last_visit", todayString, { expires: nextDay });

    logEvent(analytics, "user_activity", {
      user_id: userId, // Get the currently logged-in user's ID
      timestamp: new Date().toISOString(), // Optionally log the time of activity
      activity_type: "page_view", // Or whatever activity you're tracking
    });
  }
};

export const newUser = (userId) => {
  if (process.env.NODE_ENV === "development") return;

  logEvent(analytics, "sign_up", {
    user_id: userId, // Get the currently logged-in user's ID
    timestamp: new Date().toISOString(), // Optionally log the time of activity
    activity_type: "new_account", // Or whatever activity you're tracking
  });
};

export const cookieAccpeted = (userId) => {
  if (process.env.NODE_ENV === "development") return;

  logEvent(analytics, "cookie_accepted", {
    timestamp: new Date().toISOString(), // Optionally log the time of activity
  });
};

export const addWords = (userId) => {
  if (process.env.NODE_ENV === "development") return;

  logEvent(analytics, "new_word_added", {
    timestamp: new Date().toISOString(), // Optionally log the time of activity
    user_id: userId, // Get the currently logged-in user's ID
  });
};

export const featureRating = (userId, feature, rating) => {
  if (process.env.NODE_ENV === "development") return;

  logEvent(analytics, `feature_${feature}`, {
    timestamp: new Date().toISOString(), // Optionally log the time of activity
    user_id: userId, // Get the currently logged-in user's ID
    rating,
  });
};
