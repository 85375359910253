import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { auth, GoogleAuthProvider } from "../firebase/Firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import {
  getWordsRealTime,
  getUser,
  askStringTranslationNewLang,
  updateDocForNewLang,
  updateSubscription,
} from "../firebase/Firestore";
import translations from "../assets/languages.json";
import subscriptions from "../assets/subscriptions.json";
import Cookies from "js-cookie";
import {
  getDecryptedCookie,
  setEncryptedCookie,
  isSubExpired,
  fetchSubscriptionStatus,
  oneMonthFromNow,
  getTargetDay,
} from "../utils";

const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [navSection, setNavSection] = useState("");
  const [selectedWord, setSelectedWord] = useState("");
  const [words, setWords] = useState([]);
  const [language, setLanguage] = useState("EN");
  const [languageToLearn, setLanguageToLearn] = useState("");
  const [savedForLater, setSavedForLater] = useState([]);

  const updateWords = useCallback(async () => {
    // Check if languageToLearn, language, and currentUser are defined
    if (!languageToLearn || !language || !currentUser?.userId) return;

    const lang = translations?.EN?.[languageToLearn];
    if (lang) {
      const results = await getWordsRealTime(lang, currentUser.userId);
      if (!results) return;

      const updatedWords = await Promise.all(
        results?.map(async (word) => {
          if (!word.data.translations?.[language]) {
            const firstKey = Object.keys(word.data.translations)[0];
            const modifiedExamples = word.data.examples?.map((example) => example[firstKey]);
            const data = {
              translations: word.data.translations?.[firstKey],
              examples: modifiedExamples,
            };
            const newTranslation = await askStringTranslationNewLang(language, data);
            const newArr = newTranslation.examples.map((element, index) => {
              const newObj = { ...word.data.examples?.[index] };
              newObj[language] = element;
              return newObj;
            });
            const newWord = {
              translations: {
                ...word.data.translations,
                [language]: newTranslation.translation,
              },
              examples: newArr,
            };
            await updateDocForNewLang(word.id, newWord, lang);
            return newWord;
          }
          return word;
        })
      );

      setWords(updatedWords);
    }
  }, [languageToLearn, language, currentUser?.userId]);

  // Ensure updateWords only runs once all dependencies are defined
  useEffect(() => {
    if (languageToLearn && language && currentUser) {
      updateWords();
    }
  }, [updateWords, languageToLearn, language, currentUser]);

  // Memoize getUserFromDatabase to prevent re-renders
  const getUserFromDatabase = useCallback(async (user) => {
    if (!user.emailVerified) {
      setCurrentUser(user);
      return setLoading(false);
    }
    const savedUser = await getUser(user.uid);
    if (savedUser) {
      setLanguageToLearn(savedUser.languageToLearn);
      setLanguage(savedUser.language);
      setUser(savedUser);
    } else {
      setUser(user);
    }
    setLoading(false);
  }, []);

  // Handle auth state changes with dependency checks
  useEffect(() => {
    const parsedUser = getDecryptedCookie("user-session");

    if (parsedUser && parsedUser.languageToLearn && !currentUser) {
      setCurrentUser(parsedUser);
      setLanguageToLearn(parsedUser.languageToLearn);
      setLanguage(parsedUser.language);
      setLoading(false);
    } else if (!parsedUser) {
      const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
        if (user && !currentUser) {
          getUserFromDatabase(user);
          user.providerData.forEach((provider) => {
            if (provider.providerId === GoogleAuthProvider.PROVIDER_ID) {
              Cookies.set("LogInWithGoogle", true, { secure: true, sameSite: "Strict" });
            } else if (provider.providerId === "password") {
              Cookies.set("LogInWithGoogle", false, { secure: true, sameSite: "Strict" });
            }
          });
        } else if (!user) {
          setCurrentUser(null);
          setWords([]);
          setLoading(false);
          setNavSection(Cookies.get("section") || "Landing");
        }
      });
      return () => unsubscribeAuth();
    }
  }, [getUserFromDatabase, currentUser]);

  // Set cookies when navSection changes
  useEffect(() => {
    if (navSection) {
      Cookies.set("section", navSection, { secure: true, sameSite: "Strict" });
    }
  }, [navSection]);

  useEffect(() => {
    const savedLang = Cookies.get("lang");
    const savedSection = Cookies.get("section");
    const savedForLaterCookie = Cookies.get("savedForLater");

    if (savedLang) setLanguage(savedLang);
    if (savedSection) setNavSection(savedSection);
    if (savedForLaterCookie) setSavedForLater(savedForLaterCookie.split(","));
  }, []);

  const checkSub = useCallback(async () => {
    // TODO
    // return;
    const hasSubExpired = isSubExpired(currentUser?.expirationDate);
    if (hasSubExpired) {
      const subscription = await fetchSubscriptionStatus(currentUser?.dashboardId);
      if (subscription?.status === "canceled") {
        const newData = {
          credits: subscriptions?.["Free Plan"]?.credits,
          subscription: "Free Plan",
          expirationDate: "",
          status: "",
          renewDate: oneMonthFromNow(),
        };
        const updatedUser = await updateSubscription(currentUser?.userId, newData);
        setUser(updatedUser);
      } else if (subscription?.status === "active") {
        const newData = {
          credits: subscriptions?.[subscription?.product_name]?.credits,
          subscription: subscription?.product_name,
          expirationDate: new Date(subscription?.current_period_end * 1000),
          status: "active",
          renewDate: getTargetDay(new Date(subscription?.current_period_end * 1000)),
        };
        const updatedUser = await updateSubscription(currentUser?.userId, newData);
        console.log(updatedUser);
        setUser(updatedUser);
      }
    }
  }, [currentUser?.expirationDate, currentUser?.userId, currentUser?.dashboardId]);

  useEffect(() => {
    if (currentUser?.expirationDate && currentUser?.subscription !== "Free Plan") {
      checkSub();
    }
  }, [currentUser?.expirationDate, checkSub, currentUser?.subscription]);

  const setUser = (user) => {
    setCurrentUser(user);
    setEncryptedCookie("user-session", JSON.stringify(user), 7);
  };

  const value = {
    currentUser,
    navSection,
    selectedWord,
    words,
    language,
    languageToLearn,
    savedForLater,
    setLanguage,
    setNavSection,
    signUp: (email, password) => createUserWithEmailAndPassword(auth, email, password),
    logIn: (email, password) => signInWithEmailAndPassword(auth, email, password),
    logInWithGoogle: () => signInWithPopup(auth, new GoogleAuthProvider()),
    logOut: () => {
      auth.signOut().then(() => {
        setNavSection("landing");
        setTimeout(() => {
          setCurrentUser(null);
          Cookies.remove("user-session");
          setWords([]);
          setLanguageToLearn("");
        }, 200);
      });
    },
    setSelectedWord,
    updateWords,
    setWords,
    setCurrentUser,
    setLanguageToLearn,
    setUser,
    setSavedForLater,
    saveForLater: (word) => {
      const newList = [...savedForLater, word.replace(/[^a-zA-Z]+/g, "")];
      Cookies.set("savedForLater", newList);
      setSavedForLater(newList);
    },
    deleteSavedForLater: (word) => {
      const newList = savedForLater.filter((savedWord) => savedWord !== word);
      setSavedForLater(newList);
      Cookies.set("savedForLater", newList);
    },
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
