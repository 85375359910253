import { Button } from "@mui/material";
import React from "react";

export const UiButton = ({ variant = "contained", onClick, children, ...props }) => {
  const islarge = props?.size === "large";
  return (
    <Button
      variant={variant}
      onClick={onClick}
      {...props}
      sx={{
        textTransform: "none",
        backgroundColor: !props.color && "#118AB2",
        fontSize: { xs: "0.9rem", md: islarge ? "1.2rem" : "0.9rem" }, // Adjust font size based on viewport
        padding: { xs: "6px 10px", md: "6px 12px" }, // Adjust padding based on viewport
        minHeight: { xs: "32px", md: "36px" }, // Adjust height based on viewport
        fontFamily: "'Lilita One', sans-serif",
        border: "black solid 1px",
        ...props?.sx,
      }}
    >
      {children}
    </Button>
  );
};
