import React, { useEffect, useState } from "react";
import { useScore } from "../context/Score";
import translations from "../assets/languages.json";
import { useAuth } from "../context/Context";

const TopBar = () => {
  const { score, level, progress, setLevel, setProgress, gotNewLevel, setGotNewLevel } = useScore();
  const { language, currentUser } = useAuth();
  const translationsData = translations?.[language];

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (gotNewLevel) {
      setTimeout(() => {
        setGotNewLevel(false);
        setIsVisible(true);
      }, 500); // Hide after 700ms

      setTimeout(() => setIsVisible(false), 2000); // Hide after 700ms
    }
  }, [level, progress, setLevel, setProgress]);

  // TODO credits style
  return (
    <div className="p-2 pb-0">
      <div className="bg-blue-200 border-2  mx-auto rounded-xl border-black w-full shadow-md">
        <div className="flex justify-around  mx-auto px-10">
          <div className="flex relative items-center my-2">
            {/* TODO animations */}
            <p className="font-bold">
              {translationsData?.level} {level}
            </p>

            <div className="w-32 bg-white h-[90%] relative progress-bar mx-2 border-2 border-black">
              <div
                className={`bg-blue-400 h-full border-r-2 border-black`}
                style={{ width: `${progress}%` }}
              ></div>
            </div>

            <div
              className={`${
                isVisible
                  ? "opacity-100 scale-100 -top-[60px]" // Fully visible and scaled up
                  : "opacity-0 scale-0  top-0" // Hidden and scaled down
              } transition-all duration-700 ease-in-out transform absolute -right-5`}
            >
              {/* TODO mudar pra stevie ficando forte */}
              <img
                src={`${
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3001"
                    : process.env.REACT_APP_BASE_URL
                }/strong.png`}
                alt="Stay strong png"
                className="w-12 h-12 mx-auto"
              />
              {/* <strong className="text-red-600">Stay Strong</strong> */}
            </div>
            <p className="absolute right-[95px] top-0">{progress}%</p>
          </div>
          {currentUser.credits}
          <p className="font-bold my-2">
            {translationsData?.score}: {score}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
